import { __assign } from "tslib";
import Vue from 'vue';
import { authMapper } from 'shared/store/modules/auth';
import { accountMapper } from 'shared/store/modules/account';
import vLocaleSelect from 'shared/components/LocaleSelect';
export default Vue.extend({
    components: {
        vLocaleSelect: vLocaleSelect,
    },
    computed: __assign(__assign(__assign({}, authMapper.mapState(['isLoggedIn'])), accountMapper.mapState(['userProfile'])), { showRegisterUrl: function () {
            var _a;
            return this.isLoggedIn && ((_a = this.userProfile) === null || _a === void 0 ? void 0 : _a.isConfirmed);
        } }),
    methods: {
        lt: function (path) {
            return this.$t("shared.components.footer." + path);
        },
        getRegisterUrl: function (type) {
            var locale = this.$route.params.locale;
            return this.$config.privateUrl + "/" + locale + "/register-" + type;
        },
    },
});
