var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app", attrs: { id: "app" } },
    [
      _c("lyra-notify"),
      _c("v-header", { staticClass: "app__header" }),
      _c(
        "main",
        {
          staticClass: "app__main main",
          class: { "app__main--vheight": !this.$route.name }
        },
        [_c("router-view")],
        1
      ),
      _c("v-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }