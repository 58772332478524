import { __extends } from "tslib";
var ValidationError = /** @class */ (function (_super) {
    __extends(ValidationError, _super);
    function ValidationError(response) {
        var _this = _super.call(this, 'Response Validation Error') || this;
        _this.name = 'ValidationError';
        _this.response = response;
        return _this;
    }
    return ValidationError;
}(Error));
export default ValidationError;
