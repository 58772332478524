import Vue from 'vue';
import vAvatarProfile from 'shared/components/AvatarProfile.vue';
import vVerificationSign from 'shared/components/VerificationSign';
import getName from 'shared/utils/getName';
export default Vue.extend({
    components: {
        vAvatarProfile: vAvatarProfile,
        vVerificationSign: vVerificationSign,
    },
    props: {
        user: {
            type: undefined,
        },
        hospital: {
            type: undefined,
        },
        business: {
            type: undefined,
        },
        componentIs: {
            type: String,
            default: 'div',
        },
    },
    methods: {
        getName: getName,
    },
});
