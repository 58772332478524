import Vue from 'vue';
export default Vue.extend({
    props: {
        icon: {
            type: String,
            default: 'info',
        },
        iconType: {
            type: String,
            default: '',
        },
        iconColor: {
            type: String,
            default: '',
        },
        hideIcon: {
            type: Boolean,
            default: false,
        },
        shape: {
            type: String,
            default: 'round',
        },
        theme: {
            type: String,
            default: '',
        },
        direction: {
            type: String,
            default: '',
        },
        justify: {
            type: String,
            default: '',
        },
    },
    computed: {
        classes: function () {
            var _a;
            return _a = {},
                _a["banner-info--" + this.shape] = this.shape,
                _a["banner-info--" + this.theme] = this.theme,
                _a["banner-info--direction-" + this.direction] = this.direction,
                _a["banner-info--justify-" + this.justify] = this.justify,
                _a;
        },
    },
});
