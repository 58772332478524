import Vue from 'vue';
import vPageEmpty from 'shared/pages/empty';
export default Vue.extend({
    components: {
        vPageEmpty: vPageEmpty,
    },
    props: {
        message: {
            type: String,
            default: '',
        },
        additional: {
            type: String,
            default: '',
        },
        status: {
            type: Number,
            default: 0,
        },
    },
    metaInfo: function () {
        return {
            title: this.lt('title'),
        };
    },
    methods: {
        lt: function (path) {
            return this.$t("shared.pages.error." + path);
        },
        goToStartPage: function () {
            console.warn('Implement goToStartPage method');
        },
    },
});
