import Vue from 'vue';
var BREAKPOINTS = [
    { width: 1920, type: 'Desktop' },
    { width: 1200, type: 'Laptop' },
    { width: 768, type: 'Tablet' },
    { width: 0, type: 'Mobile' },
];
export default {
    install: function (RootVue) {
        var screen = new Vue({
            data: function () {
                return {
                    type: BREAKPOINTS[3].type,
                    width: 0,
                };
            },
            methods: {
                calculate: function () {
                    var _this = this;
                    var _a;
                    this.width = window.top.innerWidth;
                    this.type = ((_a = BREAKPOINTS.find(function (b) { return _this.width >= b.width; })) === null || _a === void 0 ? void 0 : _a.type) || this.type;
                },
            },
        });
        screen.calculate();
        window.addEventListener('resize', function () {
            screen.calculate();
        });
        Object.defineProperties(RootVue.prototype, {
            $screenType: {
                get: function () {
                    return screen.type;
                },
            },
        });
        Object.defineProperties(RootVue.prototype, {
            $screenWidth: {
                get: function () {
                    return screen.width;
                },
            },
        });
    },
};
