import getComponent from 'shared/router/getRouteComponent';
import { STREAM_TYPES } from 'shared/interfaces/streams';
var StreamsList = getComponent(function () { return import('global/pages/streamsCdn/index.vue'); });
var StreamSingle = getComponent(function () { return import('global/pages/streamsCdn/_id.vue'); });
var StreamListType = getComponent(function () { return import('global/pages/streamsCdn/_type.vue'); });
export default [
    {
        path: '/:locale/streams-cdn',
        name: 'streams-cdn',
        component: StreamsList,
        redirect: "/:locale/streams-cdn/" + STREAM_TYPES[0],
        children: STREAM_TYPES.map(function (type) { return ({
            path: type,
            name: "streams-cdn." + type,
            meta: { type: type },
            component: StreamListType,
        }); }),
    },
    {
        path: '/:locale/streams-cdn/:id',
        name: 'stream-cdn',
        component: StreamSingle,
    },
];
