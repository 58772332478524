import Vue from 'vue';
import { LyraTooltip } from '@lyra_npm/lyra.ui.components';
export default Vue.extend({
    components: {
        LyraTooltip: LyraTooltip,
    },
    props: {
        type: {
            type: String,
            default: 'basic-10',
        },
        float: {
            type: String,
            default: '',
        },
    },
});
