import { __assign, __spreadArrays } from "tslib";
import Vue from 'vue';
import clickOutside from '@lyra_npm/lyra.ui.components/directives/clickOutside';
import vAvatar from 'shared/components/Avatar.vue';
import vAvatarProfile from 'shared/components/AvatarProfile.vue';
import vAccountNotification from 'shared/components/AccountNotification.vue';
import vAccountProfile from 'shared/components/AccountProfile.vue';
import { accountMapper } from 'shared/store/modules/account';
export default Vue.extend({
    components: {
        vAvatar: vAvatar,
        vAvatarProfile: vAvatarProfile,
        vAccountProfile: vAccountProfile,
    },
    directives: {
        clickOutside: clickOutside,
    },
    data: function () {
        return {
            isOpen: false,
        };
    },
    computed: __assign(__assign(__assign({}, accountMapper.mapState(['userProfile'])), accountMapper.mapGetters([
        'selectedHospital',
        'selectedBusiness',
        'hospitalProfiles',
        'businessProfiles',
        'selectedProfileId',
    ])), { additionalProfiles: function () {
            var _this = this;
            return __spreadArrays(this.hospitalProfiles, this.businessProfiles).filter(function (_a) {
                var id = _a.id;
                return id !== _this.selectedProfileId;
            });
        },
        links: function () {
            var _a, _b;
            var locale = this.$route.params.locale;
            if (this.selectedHospital || this.selectedBusiness) {
                var id = ((_a = this.selectedHospital) === null || _a === void 0 ? void 0 : _a.id) || ((_b = this.selectedBusiness) === null || _b === void 0 ? void 0 : _b.id);
                var pathInternal = this.selectedHospital ? 'hospital' : 'business';
                var pathExternal = pathInternal.charAt(0);
                return [
                    {
                        label: this.$t('shared.components.account.channel'),
                        isExternal: true,
                        to: this.$config.globalUrl + "/" + locale + "/" + pathExternal + "/" + id,
                    },
                    {
                        label: this.$t('shared.components.account.video'),
                        to: "/" + locale + "/" + pathInternal + "/" + id + "/video",
                    },
                    this.selectedHospital && {
                        label: this.$t('shared.components.account.events'),
                        to: "/" + locale + "/" + pathInternal + "/" + id + "/events",
                    },
                    this.$config.streamService && {
                        label: this.$t('shared.components.account.streams'),
                        to: "/" + locale + "/" + pathInternal + "/" + id + "/streams-" + this.$config.streamService,
                    },
                    {
                        label: this.$t('shared.components.account.profile'),
                        to: "/" + locale + "/" + pathInternal + "/" + id + "/profile",
                    },
                ].filter(Boolean);
            }
            return [
                {
                    label: this.$t('shared.components.account.channel'),
                    isExternal: true,
                    to: this.$config.globalUrl + "/" + locale + "/u/" + this.userProfile.id,
                },
                {
                    label: this.$t('shared.components.account.video'),
                    to: "/" + locale + "/profile/video",
                },
                this.$config.streamService && {
                    label: this.$t('shared.components.account.streams'),
                    to: "/" + locale + "/profile/streams-" + this.$config.streamService,
                },
                {
                    label: this.$t('shared.components.account.profile'),
                    to: "/" + locale + "/profile",
                },
            ].filter(Boolean);
        } }),
    created: function () {
        var _this = this;
        Promise.all([this.fetchUserProfile(), this.fetchHospitals(), this.fetchBusinessProfiles()])
            .catch(function (e) { return _this.$handleError(e); });
    },
    methods: __assign(__assign({}, accountMapper.mapActions([
        'fetchUserProfile',
        'fetchHospitals',
        'fetchBusinessProfiles',
        'selectProfileId',
    ])), { logout: function () {
            console.warn('please implement this function');
        },
        hideDropdown: function () {
            this.isOpen = false;
        },
        changeSelectedProfile: function (id) {
            this.selectProfileId(id);
            this.showNotification();
        },
        showNotification: function () {
            this.$notify.neutral({ component: vAccountNotification, message: 'vAccountNotification' });
        } }),
});
