import Vue from 'vue';
import clearStorage from 'shared/utils/clearStorage';
import i18n from 'shared/setup/i18n';
var isCurrentlyInPrivateArea = function () {
    var _a = window.location, pathname = _a.pathname, href = _a.href;
    return [pathname, href].some(function (value) { return value.startsWith(Vue.prototype.$config.privateUrl); });
};
export var logout = function () {
    clearStorage();
    if (isCurrentlyInPrivateArea()) {
        var locale = i18n.locale;
        window.location.assign(Vue.prototype.$config.globalUrl + "/" + locale);
    }
};
export var forceLogout = function () {
    if (isCurrentlyInPrivateArea()) {
        window.onbeforeunload = null;
    }
    logout();
};
