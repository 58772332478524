import { __assign } from "tslib";
import initHttp from '@lyra_npm/lyra.ui.components/utils/http.js';
var http;
var locale = 'en-US';
export var setLocale = function (value) {
    locale = value === 'en' ? 'en-US' : value;
    console.info('updated Accept-Language to', locale);
};
export var setHttp = function (payload) {
    http = initHttp(__assign(__assign({}, payload), { timeout: 60000, requestSuccess: function (config) {
            var accessToken = window.localStorage.accessToken;
            if (accessToken) {
                // eslint-disable-next-line no-param-reassign
                config.headers.Authorization = "Bearer " + window.localStorage.accessToken;
            }
            // eslint-disable-next-line no-param-reassign
            config.headers['Accept-Language'] = locale;
            // eslint-disable-next-line no-param-reassign
            config.headers['Content-Type'] = 'application/json';
            return config;
        } }));
};
export var getHttp = function () { return http; };
