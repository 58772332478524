// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export default (function (importFunction) { return function () { return ({
    component: importFunction().catch(function (error) { return ({
        render: function (h) {
            return h('div', { class: 'container' }, [
                h('v-error', {
                    props: {
                        error: error,
                        icon: 'error-loading',
                        customTitle: this.$t('shared.components.error.title.component'),
                        customMessage: this.$t('shared.components.error.text.component'),
                        hideRefresh: true,
                    },
                }),
            ]);
        },
    }); }),
    delay: 200,
    timeout: 10000,
}); }; });
