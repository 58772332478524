var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-notification" },
    [
      _c("v-avatar-profile", {
        attrs: {
          user: _vm.userProfile,
          hospital: _vm.selectedHospital,
          business: _vm.selectedBusiness
        }
      }),
      _c("v-sanitized", { attrs: { html: _vm.message } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }