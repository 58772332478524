import { __extends } from "tslib";
/* eslint-disable max-classes-per-file */
import Vue from 'vue';
import fetchList from 'shared/provider/api/categories';
import { Getters, Mutations, Actions, Module, createMapper, } from 'vuex-smart-module';
var CategoriesState = /** @class */ (function () {
    function CategoriesState() {
        this.categories = [];
    }
    return CategoriesState;
}());
var CategoriesGetters = /** @class */ (function (_super) {
    __extends(CategoriesGetters, _super);
    function CategoriesGetters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return CategoriesGetters;
}(Getters));
var CategoriesMutations = /** @class */ (function (_super) {
    __extends(CategoriesMutations, _super);
    function CategoriesMutations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CategoriesMutations.prototype.setCategories = function (payload) {
        this.state.categories = payload.sort(function (a, b) { return (a.name < b.name ? -1 : 1); });
    };
    return CategoriesMutations;
}(Mutations));
var CategoriesActions = /** @class */ (function (_super) {
    __extends(CategoriesActions, _super);
    function CategoriesActions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CategoriesActions.prototype.fetchCategories = function () {
        var _this = this;
        fetchList()
            .then(function (response) {
            _this.mutations.setCategories(response);
        })
            .catch(Vue.prototype.$handleError);
    };
    return CategoriesActions;
}(Actions));
var categories = new Module({
    namespaced: true,
    state: CategoriesState,
    mutations: CategoriesMutations,
    actions: CategoriesActions,
});
export default categories;
export var categoriesMapper = createMapper(categories);
