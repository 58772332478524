import { getHttp } from 'shared/http';
import validateCategories from 'shared/provider/validators/categories';
export default (function () { return new Promise(function (resolve, reject) {
    getHttp().get('/user-api/api/categories')
        .then(function (response) {
        validateCategories(response);
        resolve(response);
    })
        .catch(reject);
}); });
