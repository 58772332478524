var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "icon-fluid",
      class: ((_obj = {}), (_obj["icon-fluid--invert"] = _vm.isInvert), _obj)
    },
    [
      _c("v-icon", {
        staticClass: "icon-fluid__icon",
        attrs: { name: _vm.name, type: "large-20" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }