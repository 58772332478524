import { isNotEmptyString, isBoolean } from '@lyra_npm/lyra.ui.components/utils/validators';
import walk from '@lyra_npm/lyra.ui.components/utils/walk';
import ValidationError from './error';
export default (function (data) {
    var isValid = walk({
        id: isNotEmptyString,
        name: isNotEmptyString,
        surname: isNotEmptyString,
        isConfirmed: isBoolean,
        isVerified: isBoolean,
        isPlatformModerator: isBoolean,
    }, data);
    if (!isValid) {
        throw new ValidationError(data);
    }
});
