import Vue from 'vue';
export default Vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
        isInvert: {
            type: Boolean,
            default: false,
        },
    },
});
