import getComponent from 'shared/router/getRouteComponent';
var Business = getComponent(function () { return import('global/pages/channel/business/index.vue'); });
var BusinessSubscribers = getComponent(function () { return import('global/pages/channel/common/subscribers.vue'); });
var BusinessSubscriptions = getComponent(function () { return import('global/pages/channel/common/subscriptions.vue'); });
var BusinessVideo = getComponent(function () { return import('global/pages/channel/common/video.vue'); });
var BusinessStreamsWowza = getComponent(function () { return import('global/pages/channel/common/streams/wowza.vue'); });
var BusinessStreamsCdn = getComponent(function () { return import('global/pages/channel/common/streams/cdn.vue'); });
export default [
    {
        path: '/:locale/b/:id',
        name: 'business',
        component: Business,
        redirect: { name: 'business.video' },
        children: [
            {
                path: 'video',
                name: 'business.video',
                component: BusinessVideo,
            },
            {
                path: 'streams-wowza',
                name: 'business.streams-wowza',
                component: BusinessStreamsWowza,
            },
            {
                path: 'streams-cdn',
                name: 'business.streams-cdn',
                component: BusinessStreamsCdn,
            },
            {
                path: 'subscribers',
                name: 'business.subscribers',
                component: BusinessSubscribers,
            },
            {
                path: 'subscriptions',
                name: 'business.subscriptions',
                component: BusinessSubscriptions,
            },
        ],
    },
];
