import getComponent from 'shared/router/getRouteComponent';
var Compilation = getComponent(function () { return import('global/pages/compilation.vue'); });
var allowedTypes = ['newest', 'popular', 'history', 'interests'];
export default [
    {
        path: '/:locale/compilation/:type',
        name: 'compilation',
        component: Compilation,
        beforeEnter: function (to, from, next) {
            if (allowedTypes.includes(to.params.type)) {
                next();
                return;
            }
            next({ name: 'dashboard' });
        },
    },
];
