import { getHttp } from 'shared/http';
import { validateList, validateSingle } from 'shared/provider/validators/hospital';
import mapHospital from 'shared/provider/mapper/hospital';
export var fetchHospitalsList = function (id) { return (new Promise(function (resolve, reject) {
    getHttp().get("/user-api/api/hospitals/users/" + id)
        .then(function (response) {
        validateList(response);
        resolve(response.map(mapHospital));
    })
        .catch(reject);
})); };
export var fetchHospitalsEditableList = function () { return (new Promise(function (resolve, reject) {
    getHttp().get('/user-api/api/hospitals/editors')
        .then(function (response) {
        validateList(response);
        resolve(response.map(mapHospital));
    })
        .catch(reject);
})); };
export var fetchHospital = function (id) { return new Promise(function (resolve, reject) {
    getHttp().get("/user-api/api/hospitals/" + id)
        .then(function (response) {
        validateSingle(response);
        resolve(mapHospital(response));
    })
        .catch(reject);
}); };
export var updateHospitalInterests = function (id, payload) { return (getHttp().patch("/user-api/api/hospitals/interests/" + id, payload)); };
