var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideDropdown,
          expression: "hideDropdown"
        }
      ],
      staticClass: "account"
    },
    [
      _c(
        "div",
        {
          staticClass: "account__data",
          on: {
            click: function($event) {
              _vm.isOpen = !_vm.isOpen
            }
          }
        },
        [
          _c("v-avatar-profile", {
            staticClass: "account__avatar",
            attrs: {
              user: _vm.userProfile,
              hospital: _vm.selectedHospital,
              business: _vm.selectedBusiness
            }
          }),
          _vm.isOpen
            ? _c("transition", { attrs: { name: "slide-up", appear: "" } }, [
                _c(
                  "div",
                  { staticClass: "account__dropdown" },
                  [
                    _c("v-account-profile", {
                      staticClass: "account__profile",
                      attrs: {
                        user: _vm.userProfile,
                        hospital: _vm.selectedHospital,
                        business: _vm.selectedBusiness
                      }
                    }),
                    _vm._l(_vm.links, function(link) {
                      return _c(
                        "div",
                        {
                          staticClass: "account__action account__action-group"
                        },
                        [
                          _c(
                            "lyra-button",
                            _vm._b(
                              {
                                key: link.to,
                                attrs: {
                                  shape: "rect",
                                  theme: "neutral",
                                  tone: "light",
                                  "component-is": link.isExternal
                                    ? "a"
                                    : "router-link"
                                }
                              },
                              "lyra-button",
                              link.isExternal
                                ? { href: link.to }
                                : { to: link.to },
                              false
                            ),
                            [_vm._v(_vm._s(link.label))]
                          )
                        ],
                        1
                      )
                    }),
                    _c(
                      "lyra-button",
                      {
                        staticClass: "account__action",
                        attrs: {
                          theme: "neutral",
                          tone: "light",
                          shape: "rect"
                        },
                        on: { click: _vm.logout }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("shared.components.account.logout"))
                        )
                      ]
                    ),
                    _c(
                      "ul",
                      { staticClass: "account__profiles" },
                      [
                        _vm._l(_vm.additionalProfiles, function(profile) {
                          return _c("v-account-profile", {
                            key: profile.id,
                            staticClass: "account__action",
                            attrs: {
                              "component-is": "lyra-button",
                              theme: "neutral",
                              tone: "light",
                              shape: "rect",
                              crush: "",
                              hospital: profile
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeSelectedProfile(profile.id)
                              }
                            }
                          })
                        }),
                        _vm.selectedHospital || _vm.selectedBusiness
                          ? _c("v-account-profile", {
                              staticClass: "account__action",
                              attrs: {
                                "component-is": "lyra-button",
                                theme: "neutral",
                                tone: "light",
                                shape: "rect",
                                crush: "",
                                user: _vm.userProfile
                              },
                              on: {
                                click: function($event) {
                                  return _vm.changeSelectedProfile("")
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  2
                )
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }