var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-page-empty", {
    attrs: {
      icon: _vm.status === 404 ? "error-404" : "failure",
      "is-horizontal": _vm.status === 404,
      theme: "neutral"
    },
    on: { click: _vm.goToStartPage },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(_vm._s(_vm.message || _vm.lt("title")))]
        },
        proxy: true
      },
      {
        key: "text",
        fn: function() {
          return [_vm._v(_vm._s(_vm.additional))]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }