import Vue from 'vue';
import Vuex from 'vuex';
import VueMeta from 'vue-meta';
import { LyraInput, LyraTextarea, LyraButton, LyraSelect, LyraSpinner, LyraNotify, LyraConfirmation, LyraCurrentTime, } from '@lyra_npm/lyra.ui.components';
import getConfig from 'shared/config';
import { setHttp } from 'shared/http';
import checkAuth from 'shared/utils/checkAuth';
import detectWebpSupport from 'src/shared/utils/detectWebpSupport';
import vIcon from 'shared/components/Icon.vue';
import vError from 'shared/components/Error.vue';
import i18n from 'shared/setup/i18n';
import setLyraUiConfig from 'shared/setup/lyraUiConfig';
import handleError from 'global/plugins/handleError';
import screenType from 'shared/plugins/screenType';
import store from 'global/store/index';
import router from 'global/router/';
import App from 'global/App.vue';
getConfig()
    .then(function (config) {
    setHttp({ baseURL: config.apiUrl });
    Vue.use(Vuex);
    Vue.use(VueMeta);
    Vue.use(handleError);
    setLyraUiConfig();
    Vue.use(LyraNotify, {
        componentOptions: {
            shownAmount: 1,
            align: 'right',
            timeout: 2000,
            shouldDestroyErrorOnTimeout: true,
            transitionDuration: { enter: 200, leave: 0 },
        },
    });
    Vue.use(LyraCurrentTime);
    Vue.use(screenType);
    Vue.component('LyraInput', LyraInput);
    Vue.component('LyraTextarea', LyraTextarea);
    Vue.component('LyraButton', LyraButton);
    Vue.component('LyraSelect', LyraSelect);
    Vue.component('LyraSpinner', LyraSpinner);
    Vue.component('VIcon', vIcon);
    Vue.component('VError', vError);
    Vue.use(LyraConfirmation, {
        props: {
            type: 'primary',
        },
    });
    Vue.prototype.$config = config;
    Vue.prototype.$historyCount = window.history.length;
    detectWebpSupport();
    return checkAuth();
})
    .then(function () {
    // eslint-disable-next-line no-new
    new Vue({
        el: '#app',
        router: router,
        i18n: i18n,
        store: store,
        render: function (h) {
            return h(App);
        },
    });
});
