import { __spreadArrays } from "tslib";
import getComponent from 'shared/router/getRouteComponent';
import editorConfirmation from './editorConfirmation';
var Hospital = getComponent(function () { return import('global/pages/channel/hospital/index.vue'); });
var HospitalSubscribers = getComponent(function () { return import('global/pages/channel/common/subscribers.vue'); });
var HospitalSubscriptions = getComponent(function () { return import('global/pages/channel/common/subscriptions.vue'); });
var HospitalVideo = getComponent(function () { return import('global/pages/channel/common/video.vue'); });
var HospitalStreamsWowza = getComponent(function () { return import('global/pages/channel/common/streams/wowza.vue'); });
var HospitalStreamsCdn = getComponent(function () { return import('global/pages/channel/common/streams/cdn.vue'); });
var HospitalEvents = getComponent(function () { return import('global/pages/channel/hospital/events.vue'); });
export default __spreadArrays([
    {
        path: '/:locale/h/:id',
        name: 'hospital',
        component: Hospital,
        redirect: { name: 'hospital.video' },
        children: [
            {
                path: 'video',
                name: 'hospital.video',
                component: HospitalVideo,
            },
            {
                path: 'streams-wowza',
                name: 'hospital.streams-wowza',
                component: HospitalStreamsWowza,
            },
            {
                path: 'streams-cdn',
                name: 'hospital.streams-cdn',
                component: HospitalStreamsCdn,
            },
            {
                path: 'events',
                name: 'hospital.events',
                component: HospitalEvents,
            },
            {
                path: 'subscribers',
                name: 'hospital.subscribers',
                component: HospitalSubscribers,
            },
            {
                path: 'subscriptions',
                name: 'hospital.subscriptions',
                component: HospitalSubscriptions,
            },
        ],
    }
], editorConfirmation);
