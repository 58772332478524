import { __assign } from "tslib";
import Vue from 'vue';
import { categoriesMapper } from 'shared/store/modules/categories';
import { LyraPopover } from '@lyra_npm/lyra.ui.components';
export default Vue.extend({
    components: {
        LyraPopover: LyraPopover,
    },
    data: function () {
        return {
            isDropdownVisible: false,
        };
    },
    computed: __assign({}, categoriesMapper.mapState(['categories'])),
    methods: {
        openDropdown: function () {
            var _this = this;
            setTimeout(function () {
                if (_this.$refs.popover) {
                    _this.$refs.popover.doShow();
                }
            }, 0);
        },
        closeDropdown: function () {
            if (this.$refs.popover) {
                this.$refs.popover.doClose();
            }
        },
    },
});
