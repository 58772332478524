var detectImageFormatSupport = function (format) {
    var canvas = document.createElement('canvas');
    if (canvas && canvas.getContext('2d')) {
        return canvas.toDataURL(format).indexOf("data:" + format) === 0;
    }
    return false;
};
export default (function () {
    var hasSupport = detectImageFormatSupport('image/webp');
    if (hasSupport && document.body) {
        document.body.classList.add('webp');
    }
});
