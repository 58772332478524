import getComponent from 'shared/router/getRouteComponent';
var RequestPasswordRecovery = getComponent(function () { return import('global/pages/requestPasswordRecovery.vue'); });
var PasswordReset = getComponent(function () { return import('global/pages/passwordReset.vue'); });
var SuccessPage = getComponent(function () { return import('global/pages/success.vue'); });
export default [
    {
        path: '/:locale/password-changed',
        name: 'password-changed',
        component: SuccessPage,
        props: {
            i18nPath: 'global.routes.passwordChanged',
            hideIfLoggedIn: true,
            withLoginControl: true,
        },
    },
    {
        path: '/:locale/request-password-recovery',
        name: 'request-password-recovery',
        component: RequestPasswordRecovery,
    },
    {
        path: '/:locale/password-reset',
        name: 'password-reset',
        component: PasswordReset,
    },
];
