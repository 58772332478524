import getComponent from 'shared/router/getRouteComponent';
var EventsList = getComponent(function () { return import('global/pages/events/index.vue'); });
var EventSingle = getComponent(function () { return import('global/pages/events/_id.vue'); });
var EventConfirmation = getComponent(function () { return import('global/pages/events/confirmation.vue'); });
var EventRefuse = getComponent(function () { return import('global/pages/events/refuse.vue'); });
export default [
    {
        path: '/:locale/events',
        name: 'events',
        component: EventsList,
    },
    {
        path: '/:locale/events/:id',
        name: 'event',
        component: EventSingle,
    },
    {
        path: '/:locale/events/:id/confirmation',
        name: 'event-confirmation',
        component: EventConfirmation,
    },
    {
        path: '/:locale/events/:id/refuse',
        name: 'event-refuse',
        component: EventRefuse,
    },
];
