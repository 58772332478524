import { getHttp } from 'shared/http';
import { validateList, validateSingle } from 'shared/provider/validators/hospital';
export var fetchBusinessProfilesList = function (id) { return (getHttp().get("/user-api/api/businessProfiles/users/" + id)
    .then(function (response) {
    validateList(response);
    return response;
})); };
export var fetchBusinessProfile = function (id) { return (getHttp().get("/user-api/api/businessProfiles/" + id)
    .then(function (response) {
    validateSingle(response);
    return response;
})); };
export var updateBusinessProfileInterests = function (id, payload) { return (getHttp().patch("/user-api/api/businessProfiles/interests/" + id, payload)); };
