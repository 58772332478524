import Vue from 'vue';
import vAvatar from 'shared/components/Avatar.vue';
export default Vue.extend({
    components: {
        vAvatar: vAvatar,
    },
    props: {
        user: {
            type: undefined,
        },
        hospital: {
            type: undefined,
        },
        business: {
            type: undefined,
        },
    },
});
