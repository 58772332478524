var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.business
    ? _c(
        "v-avatar",
        _vm._b(
          { attrs: { src: _vm.business.avatar, "is-hospital": "" } },
          "v-avatar",
          _vm.$attrs,
          false
        )
      )
    : _vm.hospital
    ? _c(
        "v-avatar",
        _vm._b(
          { attrs: { src: _vm.hospital.avatar, "is-hospital": "" } },
          "v-avatar",
          _vm.$attrs,
          false
        )
      )
    : _vm.user
    ? _c(
        "v-avatar",
        _vm._b(
          {
            attrs: {
              id: _vm.user.id,
              name: _vm.user.name,
              surname: _vm.user.surname,
              src: _vm.user.avatar
            }
          },
          "v-avatar",
          _vm.$attrs,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }