var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "lyra-popover",
    {
      ref: "popover",
      staticClass: "header__categories-wrapper",
      attrs: { placement: "bottom-start" },
      on: {
        show: function($event) {
          _vm.isDropdownVisible = true
        },
        hide: function($event) {
          _vm.isDropdownVisible = false
        }
      }
    },
    [
      _c(
        "lyra-button",
        {
          staticClass: "header__categories-button header__collapse-item",
          class: { "header__categories-button--active": _vm.isDropdownVisible },
          attrs: { slot: "reference" },
          slot: "reference"
        },
        [
          _vm._v(_vm._s(_vm.$t("shared.components.categories.title"))),
          _c("v-icon", {
            attrs: {
              name: "arrow-down",
              type: "small-60",
              color: "neutral",
              float: "after"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "header__categories" },
        [
          _vm._l(_vm.categories, function(category) {
            return _vm._t(
              "default",
              [
                _c(
                  "router-link",
                  {
                    key: category.id,
                    staticClass: "router-link--primary header__categories-item",
                    attrs: {
                      to: { name: "category", params: { id: category.id } }
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.closeDropdown($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(category.name))]
                )
              ],
              { category: category }
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }