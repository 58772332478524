var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-page-empty", {
    attrs: {
      "hide-gaps": "",
      icon: _vm.customIcon,
      invert: _vm.invert,
      "hide-action": _vm.hideRefresh,
      "action-text": _vm.$t("shared.actions.refresh")
    },
    on: {
      click: function($event) {
        return _vm.$emit("refresh")
      }
    },
    scopedSlots: _vm._u(
      [
        _vm.title
          ? {
              key: "title",
              fn: function() {
                return [_vm._v(_vm._s(_vm.title))]
              },
              proxy: true
            }
          : null,
        {
          key: "text",
          fn: function() {
            return [
              _vm.message
                ? _c("span", [_vm._v(_vm._s(_vm.message) + " ")])
                : _vm._e(),
              _vm.code
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("shared.components.error.code")) +
                        ": " +
                        _vm._s(_vm.code)
                    )
                  ])
                : _vm._e()
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }