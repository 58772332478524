import { __extends } from "tslib";
/* eslint-disable max-classes-per-file */
import Vue from 'vue';
import fetchList from 'global/provider/api/faq/index';
import { Getters, Mutations, Actions, Module, createMapper, } from 'vuex-smart-module';
var FaqState = /** @class */ (function () {
    function FaqState() {
        this.list = {
            ru: [],
            en: [],
        };
    }
    return FaqState;
}());
var FaqGetters = /** @class */ (function (_super) {
    __extends(FaqGetters, _super);
    function FaqGetters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FaqGetters;
}(Getters));
var FaqMutations = /** @class */ (function (_super) {
    __extends(FaqMutations, _super);
    function FaqMutations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FaqMutations.prototype.setFaq = function (_a) {
        var locale = _a.locale, list = _a.list;
        this.state.list[locale] = list;
    };
    return FaqMutations;
}(Mutations));
var FaqActions = /** @class */ (function (_super) {
    __extends(FaqActions, _super);
    function FaqActions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FaqActions.prototype.fetchList = function (locale) {
        var _this = this;
        if (this.state.list[locale].length) {
            return;
        }
        fetchList(locale)
            .then(function (list) {
            _this.mutations.setFaq({ locale: locale, list: list });
        })
            .catch(Vue.prototype.$handleError);
    };
    return FaqActions;
}(Actions));
var faq = new Module({
    namespaced: true,
    state: FaqState,
    mutations: FaqMutations,
    actions: FaqActions,
});
export default faq;
export var faqMapper = createMapper(faq);
