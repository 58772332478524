import { __assign } from "tslib";
import Vue from 'vue';
import { LyraTooltip } from '@lyra_npm/lyra.ui.components';
import vBannerInfo from 'shared/components/BannerInfo.vue';
import vNavCategories from 'shared/components/NavCategories.vue';
import vSearch from 'shared/components/Search.vue';
import { accountMapper } from 'shared/store/modules/account';
import { authMapper } from 'shared/store/modules/auth';
import vLogin from './Login.vue';
import vAccount from './Account.vue';
export default Vue.extend({
    components: {
        LyraTooltip: LyraTooltip,
        vLogin: vLogin,
        vAccount: vAccount,
        vBannerInfo: vBannerInfo,
        vNavCategories: vNavCategories,
        vSearch: vSearch,
    },
    data: function () {
        return {
            isHeaderCollapseActive: false,
            menuLinks: [
                {
                    to: { name: 'events' },
                    text: 'shared.links.events',
                },
                this.$config.streamService && {
                    to: { name: "streams-" + this.$config.streamService },
                    text: 'shared.links.streams',
                },
            ].filter(Boolean),
        };
    },
    computed: __assign(__assign(__assign(__assign({}, authMapper.mapState(['isLoggedIn'])), accountMapper.mapState(['userProfile'])), accountMapper.mapGetters(['selectedHospital', 'selectedBusiness'])), { createVideoUrl: function () {
            if (!this.isLoggedIn || !this.userProfile.isConfirmed) {
                return '';
            }
            var locale = this.$route.params.locale;
            var path = ((this.selectedHospital && "hospital/" + this.selectedHospital.id)
                || (this.selectedBusiness && "business/" + this.selectedBusiness.id)
                || 'profile');
            return this.$config.privateUrl + "/" + locale + "/" + path + "/video/create";
        } }),
    watch: {
        $route: function () {
            if (this.isHeaderCollapseActive) {
                this.toggleCollapse();
            }
        },
    },
    created: function () {
        this.initSilentRefresh();
    },
    methods: __assign(__assign({}, authMapper.mapActions(['initSilentRefresh'])), { toggleCollapse: function () {
            this.isHeaderCollapseActive = !this.isHeaderCollapseActive;
            if (this.isHeaderCollapseActive && this.$refs.categories) {
                this.$refs.categories.openDropdown();
            }
        },
        closeCollapse: function () {
            this.isHeaderCollapseActive = false;
        } }),
});
