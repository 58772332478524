import { __assign } from "tslib";
import Vue from 'vue';
import vSanitized from 'shared/components/Sanitized';
import { faqMapper } from 'global/store/modules/faq';
export default Vue.extend({
    metaInfo: function () {
        return {
            title: this.lt('title'),
        };
    },
    components: {
        vSanitized: vSanitized,
    },
    computed: __assign(__assign({}, faqMapper.mapState(['list'])), { locale: function () {
            return this.$route.params.locale;
        } }),
    watch: {
        locale: {
            immediate: true,
            handler: function () {
                this.fetchList(this.locale);
            },
        },
    },
    methods: __assign(__assign({}, faqMapper.mapActions(['fetchList'])), { lt: function (path) {
            return this.$t("global.routes.faq." + path);
        } }),
});
