import { render, staticRenderFns } from "./terms.vue?vue&type=template&id=5848b2a9&lang=pug&"
import script from "./terms.vue?vue&type=script&lang=ts&"
export * from "./terms.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5848b2a9')) {
      api.createRecord('5848b2a9', component.options)
    } else {
      api.reload('5848b2a9', component.options)
    }
    module.hot.accept("./terms.vue?vue&type=template&id=5848b2a9&lang=pug&", function () {
      api.rerender('5848b2a9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/global/pages/terms.vue"
export default component.exports