import Vue from 'vue';
import getErrorMessage from 'shared/utils/getErrorMessage';
import vPageEmpty from 'shared/pages/empty';
export default Vue.extend({
    components: {
        vPageEmpty: vPageEmpty,
    },
    props: {
        error: {
            type: undefined,
            default: null,
        },
        customIcon: {
            type: String,
            default: 'failure',
        },
        customTitle: {
            type: String,
            default: '',
        },
        customMessage: {
            type: String,
            default: '',
        },
        hideRefresh: {
            type: Boolean,
            default: false,
        },
        hideTitle: {
            type: Boolean,
            default: false,
        },
        hideMessage: {
            type: Boolean,
            default: false,
        },
        invert: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        title: function () {
            if (this.hideTitle) {
                return '';
            }
            return this.customTitle || this.$t('shared.components.error.title.component');
        },
        message: function () {
            if (this.hideMessage) {
                return '';
            }
            return this.customMessage || getErrorMessage(this.error);
        },
        code: function () {
            var _a;
            return (_a = this.error) === null || _a === void 0 ? void 0 : _a.status;
        },
    },
});
