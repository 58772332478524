import { isArray, isNotEmptyString, } from '@lyra_npm/lyra.ui.components/utils/validators';
import walk from '@lyra_npm/lyra.ui.components/utils/walk';
import ValidationError from './error';
var scheme = {
    id: isNotEmptyString,
    name: isNotEmptyString,
};
export var validateList = function (data) {
    var isValid = isArray(data) && data.every(function (item) { return walk(scheme, item); });
    if (!isValid) {
        throw new ValidationError(data);
    }
};
export var validateSingle = function (data) {
    var isValid = walk(scheme, data);
    if (!isValid) {
        throw new ValidationError(data);
    }
};
