import Vue from 'vue';
import vPageEmpty from 'shared/pages/empty.vue';
export default Vue.extend({
    components: {
        vPageEmpty: vPageEmpty,
    },
    computed: {
        title: function () {
            return this.$t('global.routes.terms.title');
        },
    },
    metaInfo: function () {
        return {
            title: this.title,
        };
    },
});
