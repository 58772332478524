var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "lyra-popover",
    _vm._b(
      {
        ref: "popover",
        staticClass: "locale-select",
        class: { "locale-select--active": _vm.isPopoverShown },
        on: {
          show: function($event) {
            _vm.isPopoverShown = true
          },
          hide: function($event) {
            _vm.isPopoverShown = false
          }
        }
      },
      "lyra-popover",
      _vm.popoverProps,
      false
    ),
    [
      _c(
        "lyra-button",
        {
          staticClass: "locale-select__value",
          attrs: { slot: "reference", size: "small", shape: "rect" },
          slot: "reference"
        },
        [
          _c("v-icon", { attrs: { name: "language", float: "before" } }),
          _vm._v(_vm._s(_vm.lt("options." + _vm.locale))),
          _c("v-icon", {
            staticClass: "locale-select__icon",
            attrs: { name: "arrow-down", float: "after", type: "small-60" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "locale-select__dropdown" },
        [
          _c("div", { staticClass: "locale-select__dropdown-text" }, [
            _vm._v(_vm._s(_vm.lt("text")))
          ]),
          _c(
            "lyra-button",
            {
              attrs: {
                size: "small",
                shape: "rect",
                crush: "",
                tone: "light",
                theme: _vm.locale === "en" ? "primary" : "neutral"
              },
              on: {
                click: function($event) {
                  return _vm.setLocale("en")
                }
              }
            },
            [_vm._v(_vm._s(_vm.lt("options.en")))]
          ),
          _c(
            "lyra-button",
            {
              attrs: {
                size: "small",
                shape: "rect",
                crush: "",
                tone: "light",
                theme: _vm.locale === "ru" ? "primary" : "neutral"
              },
              on: {
                click: function($event) {
                  return _vm.setLocale("ru")
                }
              }
            },
            [_vm._v(_vm._s(_vm.lt("options.ru")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }