import Vue from 'vue';
import { isObject } from '@lyra_npm/lyra.ui.components/utils/validators';
import getErrorMessage from 'shared/utils/getErrorMessage';
import store from 'global/store/';
export var isAuthError = function (_a) {
    var _b = _a.status, status = _b === void 0 ? 0 : _b, config = _a.config;
    return ((config === null || config === void 0 ? void 0 : config.url) !== '/user-api/api/account/password' && [401, 419].includes(status));
};
var handleError = function (error, onInvalidFields) {
    var _a;
    var notify = Vue.prototype.$notify || console;
    if (!isObject(error)) {
        notify.error('Error');
        console.error(error);
        return;
    }
    var invalidFields = (_a = error.data) === null || _a === void 0 ? void 0 : _a.mvsInvalidFields;
    if (isAuthError(error)) {
        store.dispatch('auth/logout');
    }
    else if (invalidFields && Object.keys(invalidFields).length && onInvalidFields) {
        onInvalidFields(invalidFields);
    }
    else {
        notify.error(getErrorMessage(error));
        console.error(error);
    }
};
export default {
    install: function (vm) {
        // eslint-disable-next-line no-param-reassign
        vm.prototype.$handleError = handleError;
    },
};
