import { __assign, __extends } from "tslib";
/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createMapper, } from 'vuex-smart-module';
import { fetchVideoRecommendations } from 'global/provider/api/video';
var VideoRecommendationsState = /** @class */ (function () {
    function VideoRecommendationsState() {
        this.recommendations = {};
    }
    return VideoRecommendationsState;
}());
var VideoRecommendationsGetters = /** @class */ (function (_super) {
    __extends(VideoRecommendationsGetters, _super);
    function VideoRecommendationsGetters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return VideoRecommendationsGetters;
}(Getters));
var VideoRecommendationsMutations = /** @class */ (function (_super) {
    __extends(VideoRecommendationsMutations, _super);
    function VideoRecommendationsMutations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VideoRecommendationsMutations.prototype.clear = function () {
        this.state.recommendations = {};
    };
    VideoRecommendationsMutations.prototype.set = function (_a) {
        var _b;
        var id = _a.id, payload = _a.payload;
        this.state.recommendations = __assign(__assign({}, this.state.recommendations), (_b = {}, _b[id] = payload, _b));
    };
    return VideoRecommendationsMutations;
}(Mutations));
var VideoRecommendationsActions = /** @class */ (function (_super) {
    __extends(VideoRecommendationsActions, _super);
    function VideoRecommendationsActions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VideoRecommendationsActions.prototype.fetchById = function (id) {
        var _this = this;
        this.commit('clear');
        fetchVideoRecommendations(id, { limit: 10, pageNumber: 0 })
            .then(function (response) {
            _this.commit('set', { id: id, payload: response.items });
        })
            .catch(console.error);
    };
    return VideoRecommendationsActions;
}(Actions));
var videoRecommendations = new Module({
    namespaced: true,
    state: VideoRecommendationsState,
    getters: VideoRecommendationsGetters,
    mutations: VideoRecommendationsMutations,
    actions: VideoRecommendationsActions,
});
export default videoRecommendations;
export var videoRecomendationsMapper = createMapper(videoRecommendations);
