var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avatar", class: _vm.classes, style: _vm.styles },
    [
      _vm.src
        ? _c("v-image", { attrs: { src: _vm.src } })
        : _vm.isHospital
        ? _c("v-icon", { attrs: { name: "hospital", type: "fluid" } })
        : _vm.initials
        ? [_vm._v(_vm._s(_vm.initials))]
        : _vm.fallbackIcon.name
        ? _c("v-icon", _vm._b({}, "v-icon", _vm.fallbackIcon, false))
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }