import { __assign } from "tslib";
import i18n from 'shared/setup/i18n';
import locales from 'locales/index';
import { setLocale } from 'shared/http';
import setDayjsLocale from 'shared/utils/setDayjsLocale';
import getDefaultLocale from 'shared/utils/getDefaultLocale';
import store from 'global/store/index';
var htmlElement = document.querySelector('html');
export default (function (to, from, next) {
    if (from.params.locale === to.params.locale) {
        next();
        return;
    }
    var locale = to.params.locale;
    if (!locales.includes(locale)) {
        next({
            name: to.name,
            params: __assign(__assign({}, to.params), { locale: getDefaultLocale() }),
        });
        return;
    }
    i18n.locale = locale;
    htmlElement.lang = locale;
    setLocale(locale);
    store.dispatch('categories/fetchCategories');
    setDayjsLocale(locale)
        .then(function () { return next(); });
    if (Object.keys(i18n.messages[locale]).length) {
        return;
    }
    import("locales/" + locale + "/global.ts")
        .then(function (response) {
        i18n.mergeLocaleMessage(locale, response.default);
    })
        .catch(console.error);
});
