var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-empty", class: _vm.classes }, [
    _vm.isFetching
      ? _c(
          "div",
          { staticClass: "page-empty__container container" },
          [_c("lyra-spinner")],
          1
        )
      : _c(
          "div",
          { staticClass: "page-empty__container container" },
          [
            _vm.isInvert
              ? _c("v-icon-fluid", {
                  staticClass: "page-empty__icon",
                  attrs: { name: _vm.icon, "is-invert": _vm.isInvert }
                })
              : _c("v-icon", {
                  staticClass: "page-empty__icon",
                  attrs: { name: _vm.icon }
                }),
            _c("div", { staticClass: "page-empty__content" }, [
              _vm.$slots.title
                ? _c(
                    "h1",
                    {
                      staticClass: "page-empty__title",
                      class: _vm.isHorizontal
                        ? "heading--large-40"
                        : "heading--large-20"
                    },
                    [_vm._t("title")],
                    2
                  )
                : _vm._e(),
              _vm.$slots.text
                ? _c(
                    "p",
                    { staticClass: "page-empty__text" },
                    [_vm._t("text")],
                    2
                  )
                : _vm._e(),
              !_vm.hideAction
                ? _c(
                    "div",
                    { staticClass: "page-empty__actions" },
                    [
                      _vm._t("actions", [
                        _c(
                          "lyra-button",
                          _vm._g(
                            {
                              attrs: {
                                "component-is": _vm.to
                                  ? "router-link"
                                  : "button",
                                theme: "primary",
                                tone: "light",
                                shape: "rect",
                                border: "",
                                crush: "",
                                to: _vm.to
                              },
                              on: { click: _vm.navigateToHome }
                            },
                            _vm.$listeners
                          ),
                          [
                            _vm._v(
                              _vm._s(
                                _vm.actionText ||
                                  _vm.$t("shared.actions.goToStartPage")
                              )
                            )
                          ]
                        )
                      ])
                    ],
                    2
                  )
                : _vm._e()
            ])
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }