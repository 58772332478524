import Vue from 'vue';
import generateColorFactory from '@lyra_npm/lyra.ui.components/utils/color.js';
import vImage from 'shared/components/Image';
var getInitials = function (string) { return (string.split(/\s+/).slice(0, 2).map(function (a) { return a[0]; }).join('')); };
var generateColor = generateColorFactory();
export default Vue.extend({
    components: {
        vImage: vImage,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        surname: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        src: {
            type: String,
            default: '',
        },
        isHospital: {
            type: Boolean,
            default: false,
        },
        fallbackIcon: {
            type: Object,
            default: function () { return ({}); },
        },
        type: {
            type: String,
            default: '',
        },
    },
    computed: {
        initials: function () {
            if (!this.name) {
                return '';
            }
            if (this.surname) {
                return this.surname[0] + this.name[0];
            }
            return getInitials(this.name);
        },
        styles: function () {
            if (!this.src && this.isHospital) {
                return {
                    '--color': '#ebebeb',
                };
            }
            if (!this.src) {
                return {
                    '--color': generateColor(this.id),
                };
            }
            return {};
        },
        classes: function () {
            var _a;
            return _a = {},
                _a["avatar--" + this.type] = this.type,
                _a;
        },
    },
});
