var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _c(
      "div",
      { staticClass: "login__buttons" },
      [
        _c(
          "lyra-button",
          {
            staticClass: "login__button",
            attrs: {
              theme: "primary",
              shape: "rect",
              size: "medium",
              border: "",
              crush: ""
            },
            on: { click: _vm.openLoginDialog }
          },
          [_vm._v(_vm._s(_vm.$t("global.actions.login")))]
        ),
        _c(
          "a",
          {
            staticClass: "lyra-button login__button mobile--hide",
            class: ["primary", "rect", "medium", "crush"].map(function(m) {
              return "lyra-button--" + m
            }),
            attrs: { href: _vm.registerUrl }
          },
          [_vm._v(_vm._s(_vm.$t("global.actions.register")))]
        )
      ],
      1
    ),
    _vm.isLoginDialogOpen
      ? _c(
          "div",
          {
            staticClass: "dialog",
            on: {
              click: function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.closeLoginDialog($event)
              }
            }
          },
          [
            _c("iframe", {
              staticClass: "dialog__container",
              attrs: { src: _vm.loginUrl }
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }