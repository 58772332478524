var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "header",
        {
          staticClass: "header",
          class: { "header--collapse": _vm.isHeaderCollapseActive }
        },
        [
          _c(
            "div",
            { staticClass: "container header__content" },
            [
              _c(
                "lyra-button",
                {
                  staticClass: "header__collapse-toggle tablet--show",
                  class: {
                    "header__collapse-toggle--active":
                      _vm.isHeaderCollapseActive
                  },
                  attrs: { shape: "square", size: "small" },
                  on: { click: _vm.toggleCollapse }
                },
                [_c("span")]
              ),
              _c(
                "router-link",
                {
                  staticClass: "header__link",
                  attrs: { to: { name: "dashboard" } }
                },
                [
                  _c("v-icon", {
                    staticClass: "header__logo-icon",
                    attrs: { name: "logo", type: "logo" }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "header__left" }, [
                _c("div", { staticClass: "header__collapse" }, [
                  _c(
                    "div",
                    { staticClass: "header__collapse-content" },
                    [
                      _c("v-nav-categories", { ref: "categories" }),
                      _vm._l(_vm.menuLinks, function(link, index) {
                        return _c(
                          "router-link",
                          {
                            key: index,
                            staticClass: "header__collapse-item",
                            attrs: { to: link.to },
                            on: { click: _vm.closeCollapse }
                          },
                          [_vm._v(_vm._s(_vm.$t(link.text)))]
                        )
                      })
                    ],
                    2
                  )
                ])
              ]),
              _c("v-search", { attrs: { "is-collapsible": "" } }),
              !_vm.isLoggedIn
                ? _c("v-login")
                : [
                    _c(
                      _vm.userProfile.isConfirmed ? "a" : "lyra-tooltip",
                      {
                        tag: "component",
                        staticClass: "header__button lyra-button",
                        class: ["square", "small", "crush"].map(function(m) {
                          return "lyra-button--" + m
                        }),
                        attrs: {
                          href: _vm.createVideoUrl,
                          "append-to-body": ""
                        }
                      },
                      [
                        _c("template", { slot: "content" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "shared.components.profile.videoUploadForbidden"
                              )
                            )
                          )
                        ]),
                        _c("v-icon", { attrs: { name: "addvideo" } })
                      ],
                      2
                    ),
                    _c("v-account")
                  ]
            ],
            2
          )
        ]
      ),
      _vm.isLoggedIn && _vm.userProfile && !_vm.userProfile.isConfirmed
        ? _c(
            "v-banner-info",
            {
              attrs: {
                theme: "primary",
                shape: "rect",
                justify: "center",
                "hide-icon": ""
              }
            },
            [_vm._v(_vm._s(_vm.$t("shared.components.profile.notConfirmed")))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }