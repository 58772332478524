import { __assign } from "tslib";
import { getHttp } from 'shared/http';
import { validateList } from 'shared/provider/validators/video';
export var fetchVideoList = function (_a) {
    var type = _a.type, params = _a.params, profileId = _a.profileId;
    return (new Promise(function (resolve, reject) {
        var path = [
            '/file-api/api/video',
            type === 'popular' ? 'top' : type,
            ['interests', 'history', 'subscriptions', 'public'].includes(type) ? profileId : '',
        ].filter(Boolean).join('/');
        getHttp().get(path, { params: params })
            .then(function (response) {
            validateList(response);
            resolve(response);
        })
            .catch(reject);
    }));
};
export var fetchVideoRecommendations = function (id, params) { return (new Promise(function (resolve, reject) {
    getHttp().get("/file-api/api/video/recommended/" + id, { params: params })
        .then(function (response) {
        validateList(response);
        resolve(response);
    })
        .catch(reject);
})); };
export var addView = function (payload) { return (getHttp().post('/file-api/api/video/addView', payload)); };
export var searchVideos = function (searchString, params) { return (getHttp().post('/file-api/api/video/search', __assign({ searchString: searchString }, params))); };
export var markAsProhibited = function (id) { return (getHttp().patch("/file-api/api/video/" + id + "/markAsProhibited")); };
