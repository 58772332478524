import { __assign } from "tslib";
import AccountBase from 'shared/components/Account';
import { authMapper } from 'shared/store/modules/auth';
export default AccountBase.extend({
    computed: {
        links: function () {
            var _a, _b;
            var locale = this.$route.params.locale;
            if (this.selectedHospital || this.selectedBusiness) {
                var id = ((_a = this.selectedHospital) === null || _a === void 0 ? void 0 : _a.id) || ((_b = this.selectedBusiness) === null || _b === void 0 ? void 0 : _b.id);
                var pathExternal = this.selectedHospital ? 'hospital' : 'business';
                var pathInternal = pathExternal.charAt(0);
                return [
                    {
                        label: this.$t('shared.components.account.channel'),
                        to: "/" + locale + "/" + pathInternal + "/" + id,
                    },
                    {
                        label: this.$t('shared.components.account.video'),
                        isExternal: true,
                        to: this.$config.privateUrl + "/" + locale + "/" + pathExternal + "/" + id + "/video",
                    },
                    this.selectedHospital && {
                        label: this.$t('shared.components.account.events'),
                        isExternal: true,
                        to: this.$config.privateUrl + "/" + locale + "/" + pathExternal + "/" + id + "/events",
                    },
                    this.$config.streamService && {
                        label: this.$t('shared.components.account.streams'),
                        isExternal: true,
                        to: this.$config.privateUrl + "/" + locale + "/" + pathExternal + "/" + id + "/streams-" + this.$config.streamService,
                    },
                    {
                        label: this.$t('shared.components.account.profile'),
                        isExternal: true,
                        to: this.$config.privateUrl + "/" + locale + "/" + pathExternal + "/" + id + "/profile",
                    },
                ].filter(Boolean);
            }
            return [
                {
                    label: this.$t('shared.components.account.channel'),
                    to: "/" + locale + "/u/" + this.userProfile.id,
                },
                {
                    label: this.$t('shared.components.account.video'),
                    isExternal: true,
                    to: this.$config.privateUrl + "/" + locale + "/profile/video",
                },
                this.$config.streamService && {
                    label: this.$t('shared.components.account.streams'),
                    isExternal: true,
                    to: this.$config.privateUrl + "/" + locale + "/profile/streams-" + this.$config.streamService,
                },
                {
                    label: this.$t('shared.components.account.profile'),
                    isExternal: true,
                    to: this.$config.privateUrl + "/" + locale + "/profile",
                },
            ].filter(Boolean);
        },
    },
    methods: __assign({}, authMapper.mapActions(['logout'])),
});
