export default (function (_a, hidePatronymic) {
    var name = _a.name, surname = _a.surname, patronymic = _a.patronymic;
    if (surname && patronymic && !hidePatronymic) {
        return surname + " " + name + " " + patronymic;
    }
    if (surname) {
        return surname + " " + name;
    }
    return name;
});
