import Vue from 'vue';
import { sanitize } from 'dompurify';
export default Vue.extend({
    props: {
        html: {
            type: String,
        },
    },
    computed: {
        sanitized: function () {
            if (!this.html) {
                return '';
            }
            return sanitize(this.html, {
                ALLOWED_TAGS: ['h3', 'p', 'strong', 'b', 'em', 'ul', 'ol', 'li', 'br', 'a'],
                ALLOWED_ATTR: ['href', 'target', 'rel'],
            });
        },
    },
});
