var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "image", class: _vm.classes }, [
    _vm.src && (_vm.isLoaded || _vm.isLoading)
      ? _c("img", {
          staticClass: "image__source",
          attrs: { loading: "lazy", src: _vm.src },
          on: {
            load: _vm.onLoad,
            error: _vm.onError,
            dragstart: function($event) {
              $event.preventDefault()
            }
          }
        })
      : _c(
          "div",
          { staticClass: "image__error" },
          [_c("v-icon", { attrs: { name: "no-image", type: "giant-60" } })],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }