import { __assign, __extends, __spreadArrays } from "tslib";
/* eslint-disable max-classes-per-file */
import Vue from 'vue';
import getUserIdFromToken from 'shared/utils/getUserIdFromToken';
import { fetchUser } from 'shared/provider/api/user';
import { fetchHospital, fetchHospitalsList, fetchHospitalsEditableList, } from 'shared/provider/api/hospital';
import { fetchBusinessProfile, fetchBusinessProfilesList, } from 'shared/provider/api/business';
import { Getters, Mutations, Actions, Module, createMapper, } from 'vuex-smart-module';
var LOCAL_STORAGE_KEY = 'selectedProfileId';
var AccountState = /** @class */ (function () {
    function AccountState() {
        this.userProfile = {
            id: getUserIdFromToken(),
            name: '',
            surname: '',
            patronymic: '',
            avatar: '',
            birthDate: '',
            login: '',
            isConfirmed: true,
            isVerified: false,
            isPlatformModerator: false,
            phoneNumber: '',
            gender: null,
            countryId: null,
            city: null,
            description: '',
            specializations: [],
            interests: [],
        };
        this.hospitals = {
            list: {
                owner: [],
                editor: [],
            },
            fullInfo: {},
        };
        this.business = {
            list: [],
            fullInfo: {},
        };
        this.selectedProfileId = '';
        this.isFetching = {
            userProfile: false,
            hospitals: false,
            business: false,
        };
    }
    return AccountState;
}());
var AccountGetters = /** @class */ (function (_super) {
    __extends(AccountGetters, _super);
    function AccountGetters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AccountGetters.prototype, "isFetchingAccount", {
        get: function () {
            return Object.values(this.state.isFetching).some(Boolean);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountGetters.prototype, "selectedProfileId", {
        get: function () {
            return (this.state.selectedProfileId || this.state.userProfile.id);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountGetters.prototype, "hospitalProfiles", {
        get: function () {
            var _this = this;
            var list = this.state.hospitals.list;
            return Object.keys(list)
                .reduce(function (acc, role) { return __spreadArrays(acc, list[role].map(function (h) { return (__assign(__assign({}, (_this.state.hospitals.fullInfo[h.id] || h)), { role: role })); })); }, []);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountGetters.prototype, "businessProfiles", {
        get: function () {
            var _this = this;
            var list = this.state.business.list;
            return list.map(function (h) { return _this.state.business.fullInfo[h.id] || h; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountGetters.prototype, "selectedHospital", {
        get: function () {
            var _this = this;
            return this.getters.hospitalProfiles.find(function (_a) {
                var id = _a.id;
                return id === _this.state.selectedProfileId;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountGetters.prototype, "selectedBusiness", {
        get: function () {
            var _this = this;
            return this.getters.businessProfiles.find(function (_a) {
                var id = _a.id;
                return id === _this.state.selectedProfileId;
            });
        },
        enumerable: false,
        configurable: true
    });
    return AccountGetters;
}(Getters));
var AccountMutations = /** @class */ (function (_super) {
    __extends(AccountMutations, _super);
    function AccountMutations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AccountMutations.prototype.setUserProfile = function (payload) {
        this.state.userProfile = __assign(__assign({}, payload), { patronymic: payload.patronymic || '', phoneNumber: payload.phoneNumber || '', description: payload.description || '', specializations: payload.specializations || [], interests: payload.interests || [] });
    };
    AccountMutations.prototype.setHospitalProfilesOwned = function (payload) {
        this.state.hospitals.list.owner = payload;
    };
    AccountMutations.prototype.setHospitalProfilesEditable = function (payload) {
        this.state.hospitals.list.editor = payload;
    };
    AccountMutations.prototype.setBusinessProfiles = function (payload) {
        this.state.business.list = payload;
    };
    AccountMutations.prototype.setIsFetching = function (_a) {
        var payload = _a.payload, field = _a.field;
        this.state.isFetching[field] = payload;
    };
    return AccountMutations;
}(Mutations));
var AccountActions = /** @class */ (function (_super) {
    __extends(AccountActions, _super);
    function AccountActions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AccountActions.prototype.fetchUserProfile = function () {
        var _this = this;
        this.commit('setIsFetching', { field: 'userProfile', payload: true });
        return fetchUser(getUserIdFromToken())
            .then(function (data) {
            _this.commit('setUserProfile', data);
        })
            .finally(function () {
            _this.commit('setIsFetching', { field: 'userProfile', payload: false });
            _this.dispatch('selectPreviousProfileId');
        });
    };
    AccountActions.prototype.fetchHospitals = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.commit('setIsFetching', { field: 'hospitals', payload: true });
            Promise.all([
                _this.dispatch('fetchHospitalsOwned'),
                _this.dispatch('fetchHospitalsEditable'),
            ])
                .then(function () { return resolve(); })
                .catch(reject)
                .finally(function () {
                _this.commit('setIsFetching', { field: 'hospitals', payload: false });
                _this.dispatch('selectPreviousProfileId');
            });
        });
    };
    AccountActions.prototype.fetchHospitalsOwned = function () {
        var _this = this;
        return fetchHospitalsList(getUserIdFromToken())
            .then(function (response) { return _this.commit('setHospitalProfilesOwned', response); });
    };
    AccountActions.prototype.fetchHospitalsEditable = function () {
        var _this = this;
        return fetchHospitalsEditableList()
            .then(function (response) { _this.commit('setHospitalProfilesEditable', response); });
    };
    AccountActions.prototype.fetchBusinessProfiles = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.commit('setIsFetching', { field: 'business', payload: true });
            fetchBusinessProfilesList(getUserIdFromToken())
                .then(function (response) {
                _this.commit('setBusinessProfiles', response);
                resolve();
            })
                .catch(reject)
                .finally(function () {
                _this.commit('setIsFetching', { field: 'business', payload: false });
                _this.dispatch('selectPreviousProfileId');
            });
        });
    };
    AccountActions.prototype.fetchSelectedHospital = function () {
        var _this = this;
        var selectedProfileId = this.state.selectedProfileId;
        return fetchHospital(selectedProfileId)
            .then(function (response) { Vue.set(_this.state.hospitals.fullInfo, selectedProfileId, response); });
    };
    AccountActions.prototype.fetchSelectedBusiness = function () {
        var _this = this;
        var selectedProfileId = this.state.selectedProfileId;
        return fetchBusinessProfile(selectedProfileId)
            .then(function (response) { Vue.set(_this.state.business.fullInfo, selectedProfileId, response); });
    };
    AccountActions.prototype.selectPreviousProfileId = function () {
        if (this.getters.isFetchingAccount) {
            return;
        }
        this.dispatch('selectProfileId', localStorage.getItem(LOCAL_STORAGE_KEY) || '');
    };
    AccountActions.prototype.selectProfileId = function (id) {
        var isHospital = this.getters.hospitalProfiles.some(function (p) { return p.id === id; });
        var isBusiness = this.getters.businessProfiles.some(function (p) { return p.id === id; });
        this.state.selectedProfileId = (isHospital || isBusiness) ? id : '';
        localStorage.setItem(LOCAL_STORAGE_KEY, this.state.selectedProfileId);
        if (isHospital) {
            this.dispatch('fetchSelectedHospital')
                .catch(console.error);
        }
        if (isBusiness) {
            this.dispatch('fetchSelectedBusiness')
                .catch(console.error);
        }
    };
    return AccountActions;
}(Actions));
var account = new Module({
    namespaced: true,
    state: AccountState,
    getters: AccountGetters,
    mutations: AccountMutations,
    actions: AccountActions,
});
export default account;
export var accountMapper = createMapper(account);
