import { __assign } from "tslib";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from 'locales/index';
import getDefaultLocale from 'shared/utils/getDefaultLocale';
import pluralize from 'shared/utils/pluralize';
Vue.use(VueI18n);
var i18n = new VueI18n({
    locale: getDefaultLocale(),
    missing: function () {
        return '';
    },
    messages: locales.reduce(function (acc, locale) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[locale] = {}, _a)));
    }, {}),
    pluralizationRules: {
        ru: pluralize,
    },
});
export default i18n;
