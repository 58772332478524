import { __assign } from "tslib";
import Vue from 'vue';
import { accountMapper } from 'shared/store/modules/account';
import vAvatarProfile from 'shared/components/AvatarProfile.vue';
import vSanitized from 'shared/components/Sanitized.vue';
export default Vue.extend({
    components: {
        vAvatarProfile: vAvatarProfile,
        vSanitized: vSanitized,
    },
    computed: __assign(__assign(__assign({}, accountMapper.mapState(['userProfile'])), accountMapper.mapGetters(['selectedHospital', 'selectedBusiness'])), { message: function () {
            var _a, _b;
            if (this.selectedHospital || this.selectedBusiness) {
                return this.$t('shared.components.accountNotification.other', {
                    name: ((_a = this.selectedHospital) === null || _a === void 0 ? void 0 : _a.name) || ((_b = this.selectedBusiness) === null || _b === void 0 ? void 0 : _b.name),
                });
            }
            return this.$t('shared.components.accountNotification.user');
        } }),
});
