import getComponent from 'shared/router/getRouteComponent';
var User = getComponent(function () { return import('global/pages/channel/user/index.vue'); });
var UserExperience = getComponent(function () { return import('global/pages/channel/user/experience.vue'); });
var UserSubscribers = getComponent(function () { return import('global/pages/channel/common/subscribers.vue'); });
var UserSubscriptions = getComponent(function () { return import('global/pages/channel/common/subscriptions.vue'); });
var UserVideo = getComponent(function () { return import('global/pages/channel/common/video.vue'); });
var UserStreamsWowza = getComponent(function () { return import('global/pages/channel/common/streams/wowza.vue'); });
var UserStreamsCdn = getComponent(function () { return import('global/pages/channel/common/streams/cdn.vue'); });
export default [
    {
        path: '/:locale/u/:id',
        name: 'user',
        component: User,
        redirect: { name: 'user.video' },
        children: [
            {
                path: 'video',
                name: 'user.video',
                component: UserVideo,
            },
            {
                path: 'experience',
                name: 'user.experience',
                component: UserExperience,
            },
            {
                path: 'subscribers',
                name: 'user.subscribers',
                component: UserSubscribers,
            },
            {
                path: 'subscriptions',
                name: 'user.subscriptions',
                component: UserSubscriptions,
            },
            {
                path: 'streams-wowza',
                name: 'user.streams-wowza',
                component: UserStreamsWowza,
            },
            {
                path: 'streams-cdn',
                name: 'user.streams-cdn',
                component: UserStreamsCdn,
            },
        ],
    },
];
