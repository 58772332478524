import getComponent from 'shared/router/getRouteComponent';
var Category = getComponent(function () { return import('global/pages/category/index.vue'); });
var CategoryVideos = getComponent(function () { return import('global/pages/category/videos.vue'); });
var CategoryAuthors = getComponent(function () { return import('global/pages/category/authors.vue'); });
var CategoryEvents = getComponent(function () { return import('global/pages/category/events.vue'); });
var CategoryStreamsWowza = getComponent(function () { return import('global/pages/category/streamsWowza.vue'); });
var CategoryStreamsCdn = getComponent(function () { return import('global/pages/category/streamsCdn.vue'); });
export default [
    {
        path: '/:locale/c/:id',
        name: 'category',
        redirect: '/:locale/c/:id/videos',
        component: Category,
        children: [
            {
                path: 'videos',
                name: 'category.videos',
                component: CategoryVideos,
            },
            {
                path: 'authors',
                name: 'category.authors',
                component: CategoryAuthors,
            },
            {
                path: 'events',
                name: 'category.events',
                component: CategoryEvents,
            },
            {
                path: 'streams-wowza',
                name: 'category.streams-wowza',
                component: CategoryStreamsWowza,
            },
            {
                path: 'streams-cdn',
                name: 'category.streams-cdn',
                component: CategoryStreamsCdn,
            },
        ],
    },
];
