var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search",
      class: { "search--collapse": _vm.isExpanded && _vm.isCollapsible }
    },
    [
      _vm.isLinkVisible
        ? [
            _vm.useExternalRoute
              ? _c(
                  "a",
                  {
                    staticClass: "header__button lyra-button",
                    class: ["square", "small", "crush"].map(function(m) {
                      return "lyra-button--" + m
                    }),
                    attrs: { href: _vm.externalRoute }
                  },
                  [_c("v-icon", { attrs: { name: "search" } })],
                  1
                )
              : _c(
                  "router-link",
                  {
                    staticClass: "header__button lyra-button",
                    class: ["square", "small", "crush"].map(function(m) {
                      return "lyra-button--" + m
                    }),
                    attrs: { to: _vm.localRoute }
                  },
                  [_c("v-icon", { attrs: { name: "search" } })],
                  1
                )
          ]
        : _vm.isInputVisible
        ? [
            _vm.isExpanded
              ? _c(
                  "lyra-input",
                  {
                    staticClass: "lyra-base-input--large",
                    attrs: {
                      autofocus: _vm.isCollapsible,
                      placeholder: _vm.$t(
                        "shared.components.search.placeholder"
                      )
                    },
                    nativeOn: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onEnter($event)
                      }
                    },
                    model: {
                      value: _vm.query,
                      callback: function($$v) {
                        _vm.query = $$v
                      },
                      expression: "query"
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "inner-before" },
                      [_c("v-icon", { attrs: { name: "search" } })],
                      1
                    ),
                    _vm.isCollapsible || _vm.query
                      ? _c(
                          "lyra-button",
                          {
                            attrs: {
                              slot: "inner-after",
                              theme: "neutral",
                              tone: "light",
                              shape: "circle",
                              size: "small"
                            },
                            on: { click: _vm.clear },
                            slot: "inner-after"
                          },
                          [_c("v-icon", { attrs: { name: "cross" } })],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              : _c(
                  "lyra-button",
                  {
                    staticClass: "header__button",
                    attrs: { shape: "square", size: "small" },
                    on: { click: _vm.expand }
                  },
                  [_c("v-icon", { attrs: { name: "search" } })],
                  1
                )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }