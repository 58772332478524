import { isNotEmptyString, isArray, isObject, isNumber, } from '@lyra_npm/lyra.ui.components/utils/validators';
import walk from '@lyra_npm/lyra.ui.components/utils/walk';
import ValidationError from 'shared/provider/validators/error';
var scheme = {
    item: {
        id: isNotEmptyString,
        owner: isObject,
        publisher: isObject,
        duration: isNumber,
        viewCount: isNumber,
        processingProgress: isNumber,
        publishDate: isNotEmptyString,
        tags: function (data) { return isArray(data) && data.every(function (item) { return isObject(item); }); },
        state: function (str) { return ['uploading', 'uploaded', 'processing', 'error', 'uploadError', 'ready'].includes(str); },
    },
    list: {
        pageCount: isNumber,
        items: function (data) { return isArray(data) && data.every(function (item) { return walk(scheme.item, item); }); },
    },
};
export var validateVideo = function (data) {
    var isValid = walk(scheme.item, data);
    if (!isValid) {
        throw new ValidationError(data);
    }
};
export var validateList = function (data) {
    var isValid = walk(scheme.list, data);
    if (!isValid) {
        throw new ValidationError(data);
    }
};
