import { __assign } from "tslib";
import Vue from 'vue';
import { authMapper } from 'shared/store/modules/auth';
export default Vue.extend({
    computed: __assign(__assign({}, authMapper.mapState(['isLoginDialogOpen'])), { loginUrl: function () {
            return this.$config.loginUrl + "?locale=" + this.$i18n.locale;
        },
        registerUrl: function () {
            return this.$config.registerUrl + "?locale=" + this.$i18n.locale;
        } }),
    methods: __assign({}, authMapper.mapActions(['openLoginDialog', 'closeLoginDialog'])),
});
