import { isObject } from '@lyra_npm/lyra.ui.components/utils/validators';
export default (function (error, showDetailedMessage) {
    var _a, _b, _c, _d, _e;
    var logMark = (_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.mvsLogMark;
    var message = (((_b = error === null || error === void 0 ? void 0 : error.data) === null || _b === void 0 ? void 0 : _b.mvsErrorMessage) || ((_c = error === null || error === void 0 ? void 0 : error.data) === null || _c === void 0 ? void 0 : _c.mvsServerMessage)
        || (error === null || error === void 0 ? void 0 : error.message)
        || (error === null || error === void 0 ? void 0 : error.statusText)
        || 'Error');
    var detailedMessage = (showDetailedMessage
        && isObject((_d = error === null || error === void 0 ? void 0 : error.data) === null || _d === void 0 ? void 0 : _d.mvsInvalidFields)
        && Object.values((_e = error === null || error === void 0 ? void 0 : error.data) === null || _e === void 0 ? void 0 : _e.mvsInvalidFields).join('<br>'));
    if (logMark) {
        return message + "<br>logMark: " + logMark;
    }
    return detailedMessage || message;
});
