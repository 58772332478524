var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.componentIs,
    _vm._g(
      _vm._b(
        { tag: "component", staticClass: "account-profile" },
        "component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("v-avatar-profile", {
        staticClass: "account-profile__avatar",
        attrs: {
          user: _vm.user,
          hospital: _vm.hospital,
          business: _vm.business
        }
      }),
      _c(
        "div",
        { staticClass: "account-profile__data" },
        [
          _c("div", { staticClass: "text-crop" }, [
            _vm._v(
              _vm._s(_vm.getName(_vm.hospital || _vm.business || _vm.user))
            )
          ]),
          !_vm.hospital && !_vm.business && _vm.user && _vm.user.isVerified
            ? _c("v-verification-sign", { attrs: { float: "after" } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }