import { getHttp } from 'shared/http';
import validateUser from 'shared/provider/validators/user';
export var fetchUser = function (id) { return new Promise(function (resolve, reject) {
    getHttp().get("/user-api/api/users/" + id)
        .then(function (response) {
        validateUser(response);
        resolve(response);
    })
        .catch(reject);
}); };
export var checkToken = function (code) { return (getHttp().patch('/user-api/api/account/password/restore/validate', {}, {
    transformRequest: [function (data, headers) {
            // eslint-disable-next-line no-param-reassign
            headers.Authorization = "Bearer " + code;
            return JSON.stringify(data);
        }],
})); };
export var getSetPasswordToken = function (payload) { return (getHttp().post('/userauth-api/api/userTokens/setPassword', payload)); };
export var setPassword = function (payload, token) { return (getHttp().patch('/user-api/api/account/password', payload, token ? {
    transformRequest: [function (data, headers) {
            // eslint-disable-next-line no-param-reassign
            headers.Authorization = "Bearer " + token;
            return JSON.stringify(data);
        }],
} : undefined)); };
export var updateInterests = function (payload) { return (getHttp().patch('/user-api/api/account/interests', payload)); };
