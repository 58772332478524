var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "footer__content container" }, [
      _c("div", { staticClass: "footer__sections" }, [
        _c(
          "div",
          { staticClass: "footer__section" },
          [
            _c("div", { staticClass: "footer__title" }, [
              _vm._v(_vm._s(_vm.lt("information.title")))
            ]),
            _c(
              "router-link",
              {
                staticClass: "link--neutral",
                attrs: { to: { name: "support" } }
              },
              [_vm._v(_vm._s(_vm.lt("information.actions.support")))]
            ),
            _c(
              "router-link",
              { staticClass: "link--neutral", attrs: { to: { name: "faq" } } },
              [_vm._v(_vm._s(_vm.lt("information.actions.faq")))]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "footer__section footer__section--span-2" },
          [
            _c("div", { staticClass: "footer__title" }, [
              _vm._v(_vm._s(_vm.lt("product.title")))
            ]),
            _vm.showRegisterUrl
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "link--neutral",
                      attrs: { href: _vm.getRegisterUrl("hospital") }
                    },
                    [_vm._v(_vm._s(_vm.lt("product.actions.registerHospital")))]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link--neutral",
                      attrs: { href: _vm.getRegisterUrl("business") }
                    },
                    [_vm._v(_vm._s(_vm.lt("product.actions.registerBusiness")))]
                  )
                ]
              : _vm._e(),
            _c(
              "a",
              {
                staticClass: "link--neutral",
                attrs: {
                  href:
                    _vm.$config.globalUrl +
                    "/static/user-agreement-and-policy.pdf",
                  target: "_blank"
                }
              },
              [_vm._v(_vm._s(_vm.lt("product.actions.privacy")))]
            ),
            _c(
              "router-link",
              {
                staticClass: "link--neutral",
                attrs: { to: { name: "terms" } }
              },
              [_vm._v(_vm._s(_vm.lt("product.actions.terms")))]
            )
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "footer__section footer__section--revert" },
          [_c("v-locale-select")],
          1
        )
      ]),
      _c("div", { staticClass: "footer__copyright" }, [
        _vm._v("© Medikt, 2020 – 2022")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }