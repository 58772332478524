var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banner-info", class: _vm.classes },
    [
      !_vm.hideIcon
        ? _c("v-icon", {
            staticClass: "banner-info__icon",
            attrs: {
              name: _vm.icon,
              type: _vm.iconType,
              color: _vm.iconColor,
              float: "before"
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "banner-info__data" }, [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }