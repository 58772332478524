import { __assign } from "tslib";
import Vue from 'vue';
import { LyraPopover } from '@lyra_npm/lyra.ui.components';
export default Vue.extend({
    components: {
        LyraPopover: LyraPopover,
    },
    data: function () {
        return {
            locale: '',
            isPopoverShown: false,
            popoverProps: {
                options: {
                    modifiers: {
                        offset: { offset: '0, 6' },
                    },
                },
            },
        };
    },
    watch: {
        '$route.params.locale': {
            handler: function () {
                this.locale = this.$route.params.locale;
            },
            immediate: true,
        },
        locale: function () {
            if (!this.locale || this.$route.params.locale === this.locale) {
                return;
            }
            this.$router.push({
                name: this.$route.name,
                params: __assign(__assign({}, this.$route.params), { locale: this.locale }),
                query: this.$route.query,
            });
        },
    },
    methods: {
        lt: function (path) {
            return this.$t("shared.components.localeSelect." + path);
        },
        setLocale: function (locale) {
            this.locale = locale;
            this.$refs.popover.doClose();
        },
    },
});
