import Vue from 'vue';
export default Vue.extend({
    props: {
        src: {
            type: String,
            default: '',
        },
        dark: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            isLoaded: false,
            isLoading: false,
        };
    },
    computed: {
        classes: function () {
            return {
                'image--dark': this.dark,
                'image--loading': this.isLoading,
            };
        },
    },
    watch: {
        src: {
            immediate: true,
            handler: function () {
                this.isLoaded = false;
                this.isLoading = !!this.src;
            },
        },
    },
    methods: {
        onLoad: function () {
            this.isLoaded = true;
            this.isLoading = false;
        },
        onError: function () {
            this.isLoaded = false;
            this.isLoading = false;
        },
    },
});
