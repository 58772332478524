var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-page-empty", {
    attrs: { icon: "soon", "is-horizontal": "" },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(_vm._s(_vm.$t("global.routes.conditions.title")))]
        },
        proxy: true
      },
      {
        key: "text",
        fn: function() {
          return [_vm._v(_vm._s(_vm.$t("global.routes.conditions.text")))]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }