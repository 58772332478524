import Vue from 'vue';
import vHeader from 'global/components/Header.vue';
import vFooter from 'global/components/Footer.vue';
export default Vue.extend({
    components: {
        vHeader: vHeader,
        vFooter: vFooter,
    },
    metaInfo: {
        title: 'Medikt',
        titleTemplate: '%s - Medikt',
    },
    created: function () {
        this.subscribe();
    },
    methods: {
        subscribe: function () {
            var _this = this;
            this.$store.subscribe(function (_a) {
                var _b, _c;
                var type = _a.type, payload = _a.payload;
                if (type === 'auth/redirect' && ((_b = payload === null || payload === void 0 ? void 0 : payload.to) === null || _b === void 0 ? void 0 : _b.name)) {
                    _this.$router.push({ name: (_c = payload === null || payload === void 0 ? void 0 : payload.to) === null || _c === void 0 ? void 0 : _c.name, query: { time: Date.now().toString() } });
                }
            });
        },
    },
});
