var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-grid questions-page" }, [
    _c("div", { staticClass: "container" }, [
      _c("h1", { staticClass: "heading--large-30 layout-inner" }, [
        _vm._v(_vm._s(_vm.lt("title")))
      ]),
      _c("div", { staticClass: "page-grid__container" }, [
        _c(
          "div",
          { staticClass: "page-grid__column" },
          _vm._l(_vm.list[_vm.locale], function(item, n) {
            return _c(
              "details",
              { key: n, staticClass: "details details--border" },
              [
                _c("summary", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "details__title heading--basic-10 questions-page__title"
                    },
                    [_vm._v(_vm._s(item.question))]
                  )
                ]),
                _c("v-sanitized", {
                  staticClass: "details__content",
                  attrs: { html: item.answer }
                })
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "page-grid__cell" },
          [
            _c("h2", { staticClass: "heading--large-10" }, [
              _vm._v(_vm._s(_vm.lt("askQuestion.title")))
            ]),
            _c("div", { staticClass: "mt-4" }, [
              _vm._v(_vm._s(_vm.lt("askQuestion.subTitle")))
            ]),
            _c(
              "lyra-button",
              {
                staticClass: "mt-6",
                attrs: {
                  theme: "primary",
                  shape: "rect",
                  "component-is": "router-link",
                  to: { name: "support" }
                }
              },
              [_vm._v(_vm._s(_vm.lt("askQuestion.action")))]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }