import { setTokens } from '@lyra_npm/lyra.ui.components/utils/SilentRefresh';
import getUserIdFromToken from 'shared/utils/getUserIdFromToken';
import loginByRefreshToken from 'shared/provider/api/refresh';
import clearStorage from 'shared/utils/clearStorage';
export default (function () { return new Promise(function (resolve) {
    var _a = window.localStorage, accessToken = _a.accessToken, accessTokenExp = _a.accessTokenExp, refreshToken = _a.refreshToken;
    if (!accessToken || !refreshToken || !accessTokenExp) {
        clearStorage();
        resolve();
        return;
    }
    if ((accessTokenExp * 1000) - Date.now() > 60000) {
        resolve();
        return;
    }
    loginByRefreshToken({
        accessToken: { sub: getUserIdFromToken() },
        refreshToken: refreshToken,
    })
        .then(setTokens)
        .catch(clearStorage)
        .finally(resolve);
}); });
