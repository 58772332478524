import { __spreadArrays } from "tslib";
import Vue from 'vue';
import VueRouter from 'vue-router';
import beforeEachHook from 'global/router/hooks/beforeEach';
import locales from 'locales/index';
import getDefaultLocale from 'shared/utils/getDefaultLocale';
import getBaseUri from 'shared/utils/getBaseUri';
import getComponent from 'shared/router/getRouteComponent';
import afterEachHook from 'shared/router/hooks/afterEach';
import ErrorPage from 'global/pages/error.vue';
import Terms from 'global/pages/terms.vue';
import Conditions from 'global/pages/conditions.vue';
import Contract from 'global/pages/contract.vue';
import FAQ from 'global/pages/faq.vue';
import passwordRoutes from 'global/router/modules/password';
import streamsWowzaRoutes from 'global/router/modules/streamsWowza';
import streamsWowzaRCdn from 'global/router/modules/streamsCdn';
import userRoutes from 'global/router/modules/user';
import categoryRoutes from 'global/router/modules/category';
import hospitalRoutes from 'global/router/modules/hospital';
import businessRoutes from 'global/router/modules/business';
import compilationRoutes from 'global/router/modules/compilation';
import eventRoutes from 'global/router/modules/events';
var Dashboard = getComponent(function () { return import('global/pages/dashboard.vue'); });
var ConfirmRegistration = getComponent(function () { return import('global/pages/confirmRegistration.vue'); });
var EmailConfirm = getComponent(function () { return import('global/pages/emailConfirm.vue'); });
var SubscribtionsVideos = getComponent(function () { return import('global/pages/videos/subscriptions.vue'); });
var Search = getComponent(function () { return import('global/pages/videos/search.vue'); });
var Video = getComponent(function () { return import('global/pages/video.vue'); });
var Support = getComponent(function () { return import('global/pages/support.vue'); });
var SuccessPage = getComponent(function () { return import('global/pages/success.vue'); });
Vue.use(VueRouter);
var routes = __spreadArrays([
    {
        path: '/:locale/',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/:locale/confirm-registration',
        name: 'confirm-registration',
        component: ConfirmRegistration,
    },
    {
        path: '/:locale/success-account-deactivation',
        name: 'success-account-deactivation',
        component: SuccessPage,
        props: {
            i18nPath: 'global.routes.accountDeactivated',
            canNavigateToStartPage: true,
            hideIfLoggedIn: true,
        },
    },
    {
        path: '/:locale/success-profile-deactivation',
        name: 'success-profile-deactivation',
        component: SuccessPage,
        props: {
            i18nPath: 'global.routes.profileDeactivated',
            canNavigateToStartPage: true,
        },
    },
    {
        path: '/:locale/email-confirm',
        name: 'email-confirm',
        component: EmailConfirm,
    },
    {
        path: '/:locale/subscriptions-videos',
        name: 'subscriptions-videos',
        component: SubscribtionsVideos,
    },
    {
        path: '/:locale/search',
        name: 'search',
        component: Search,
    },
    {
        path: '/:locale/v/:id',
        name: 'video',
        component: Video,
    },
    {
        path: '/:locale/support',
        name: 'support',
        component: Support,
    },
    {
        path: '/:locale/error',
        name: 'error',
        props: true,
        component: ErrorPage,
    },
    {
        path: '/:locale/success',
        name: 'success',
        props: true,
        component: SuccessPage,
    },
    {
        path: '/:locale/terms',
        name: 'terms',
        component: Terms,
    },
    {
        path: '/:locale/conditions',
        name: 'conditions',
        component: Conditions,
    },
    {
        path: '/:locale/contract',
        name: 'contract',
        component: Contract,
    },
    {
        path: '/:locale/faq',
        name: 'faq',
        component: FAQ,
    }
], passwordRoutes, userRoutes, hospitalRoutes, businessRoutes, categoryRoutes, streamsWowzaRoutes, streamsWowzaRCdn, eventRoutes, compilationRoutes, [
    {
        path: '*',
        redirect: function (from) {
            var locale = locales.find(function (l) { var _a; return (_a = from === null || from === void 0 ? void 0 : from.path) === null || _a === void 0 ? void 0 : _a.startsWith("/" + l + "/"); }) || getDefaultLocale();
            return "/" + locale + "/";
        },
    },
]);
var router = new VueRouter({
    routes: routes,
    mode: 'history',
    base: getBaseUri(window.location.pathname) + "/",
});
router.beforeEach(beforeEachHook);
router.afterEach(afterEachHook);
export default router;
