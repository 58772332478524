import { __assign } from "tslib";
import Vue from 'vue';
import { isString } from '@lyra_npm/lyra.ui.components/utils/validators';
var SEARCH_ROUTE_NAME = 'search';
export default Vue.extend({
    props: {
        isCollapsible: {
            type: Boolean,
            default: false,
        },
        useExternalRoute: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            query: '',
            isExpanded: !this.isCollapsible,
        };
    },
    computed: {
        isLinkVisible: function () {
            return !this.isDesktop && this.isCollapsible;
        },
        isInputVisible: function () {
            return (this.$route.name !== SEARCH_ROUTE_NAME || !this.isCollapsible);
        },
        isDesktop: function () {
            return ['Desktop', 'Laptop'].includes(this.$screenType);
        },
        localRoute: function () {
            if (this.$route.name === SEARCH_ROUTE_NAME) {
                return {
                    name: SEARCH_ROUTE_NAME,
                    query: this.$route.query,
                };
            }
            return {
                name: SEARCH_ROUTE_NAME,
            };
        },
        externalRoute: function () {
            var locale = this.$route.params.locale;
            return this.$config.globalUrl + "/" + locale + "/" + SEARCH_ROUTE_NAME;
        },
    },
    watch: {
        '$route.query': {
            immediate: true,
            handler: function () {
                this.updateQueryFromRoute();
            },
        },
        '$route.name': {
            handler: function () {
                this.clear();
            },
        },
    },
    methods: {
        expand: function () {
            if (this.isCollapsible) {
                this.isExpanded = true;
                this.updateQueryFromRoute();
            }
        },
        collapse: function () {
            if (this.isCollapsible) {
                this.isExpanded = false;
            }
        },
        clear: function () {
            this.query = '';
            this.collapse();
        },
        updateQueryFromRoute: function () {
            var _a;
            var q = (_a = this.$route.query) === null || _a === void 0 ? void 0 : _a.q;
            this.query = isString(q) ? q : '';
        },
        onEnter: function () {
            var _a;
            this.query = this.query.trim();
            if (this.useExternalRoute) {
                window.location.assign(this.externalRoute + "?q=" + this.query);
                return;
            }
            if (this.query === ((_a = this.$route.query) === null || _a === void 0 ? void 0 : _a.q)) {
                return;
            }
            this.$router.push(__assign(__assign({}, this.localRoute), { query: {
                    q: this.query,
                } }));
        },
    },
});
