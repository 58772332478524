export default (function (choice, choicesLength) {
    if (choice === 0) {
        return 0;
    }
    var teen = choice > 10 && choice < 20;
    var endsWithOne = choice % 10 === 1;
    if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
    }
    if (!teen && endsWithOne) {
        return 1;
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
    }
    return (choicesLength < 4) ? 2 : 3;
});
