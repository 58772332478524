import walk from '@lyra_npm/lyra.ui.components/utils/walk.js';
import { isString, isURLWithProtocols, } from '@lyra_npm/lyra.ui.components/utils/validators.js';
import getBaseUri from 'shared/utils/getBaseUri';
import appendOrigin from 'shared/utils/appendOrigin';
var isHttpUrl = isURLWithProtocols('http', 'https');
var removeTrailingSlash = function (string) { return (string === '/'
    ? string
    : string.replace(/\/+$/, '')); };
var validator = function (object) { return walk({
    globalUrl: isString,
    loginUrl: isString,
    registerUrl: isString,
    privateUrl: isString,
}, object); };
var fallback = {
    apiUrl: window.location.origin,
    globalUrl: window.location.origin,
    loginUrl: window.location.origin,
    registerUrl: window.location.origin,
    privateUrl: window.location.origin,
    streamService: '',
};
var getApiUrl = function (raw) {
    if (isHttpUrl(raw)) {
        return raw;
    }
    if (raw.startsWith('/')) {
        return fallback.apiUrl + raw.replace(/\/+$/, '');
    }
    return fallback.apiUrl;
};
var getStreamService = function (raw) {
    if (!isString(raw)) {
        return fallback.streamService;
    }
    if (raw.toLowerCase() === 'cdn') {
        return 'cdn';
    }
    if (raw.toLowerCase() === 'wowza') {
        return 'wowza';
    }
    return fallback.streamService;
};
var fetchConfig = function (url) { return new Promise(function (resolve, reject) {
    fetch(url)
        .then(function (r) { return r.json(); })
        .then(function (config) {
        if (!validator(config)) {
            throw new Error('Invalid configuration');
        }
        resolve({
            apiUrl: getApiUrl(config.apiUrl),
            globalUrl: appendOrigin(getBaseUri(removeTrailingSlash(config.globalUrl))),
            loginUrl: removeTrailingSlash(config.loginUrl),
            registerUrl: removeTrailingSlash(config.registerUrl),
            privateUrl: getBaseUri(removeTrailingSlash(config.privateUrl)),
            streamService: getStreamService(config.streamService),
        });
    })
        .catch(reject);
}); };
export default (function () { return new Promise(function (resolve) {
    var baseURI = getBaseUri(window.location.pathname);
    fetchConfig(baseURI + "/config.json")
        .then(resolve)
        .catch(function () {
        // if baseURI/config.json returned index.html, try to fetch config from /config.json
        fetchConfig('/config.json')
            .then(resolve)
            .catch(function () { return resolve(fallback); });
    });
}); });
