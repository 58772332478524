import Vue from 'vue';
import vuex from 'vuex';
import { createStore, Module } from 'vuex-smart-module';
import account from 'shared/store/modules/account';
import categories from 'shared/store/modules/categories';
import auth from 'shared/store/modules/auth';
import videoRecommendations from 'global/store/modules/videoRecommendations';
import faq from 'global/store/modules/faq';
Vue.use(vuex);
export default createStore(new Module({
    modules: {
        account: account,
        categories: categories,
        auth: auth,
        videoRecommendations: videoRecommendations,
        faq: faq,
    },
}));
