import { __extends } from "tslib";
/* eslint-disable max-classes-per-file */
import { Getters, Mutations, Actions, Module, createMapper, } from 'vuex-smart-module';
import SilentRefresh, { setTokens } from '@lyra_npm/lyra.ui.components/utils/SilentRefresh';
import parseJson from '@lyra_npm/lyra.ui.components/utils/parseJson.js';
import loginByRefreshToken from 'shared/provider/api/refresh';
import { forceLogout } from 'shared/utils/logout';
import getUserIdFromToken from 'shared/utils/getUserIdFromToken';
var AuthState = /** @class */ (function () {
    function AuthState() {
        this.isLoggedIn = false;
        this.isLoginDialogOpen = false;
        this.silentRefresh = null;
    }
    return AuthState;
}());
var AuthGetters = /** @class */ (function (_super) {
    __extends(AuthGetters, _super);
    function AuthGetters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AuthGetters;
}(Getters));
var AuthMutations = /** @class */ (function (_super) {
    __extends(AuthMutations, _super);
    function AuthMutations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AuthMutations.prototype.setIsLoggedIn = function (payload) {
        this.state.isLoggedIn = payload;
    };
    AuthMutations.prototype.redirect = function (payload) { }; // eslint-disable-line
    return AuthMutations;
}(Mutations));
var AuthActions = /** @class */ (function (_super) {
    __extends(AuthActions, _super);
    function AuthActions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AuthActions.prototype.openLoginDialog = function () {
        var _this = this;
        this.state.isLoginDialogOpen = true;
        window.onmessage = function (event) { return _this.dispatch('onWindowMessage', event); };
    };
    AuthActions.prototype.closeLoginDialog = function () {
        this.state.isLoginDialogOpen = false;
        window.onmessage = null;
    };
    AuthActions.prototype.onWindowMessage = function (event) {
        var _this = this;
        parseJson(event.data)
            .then(function (parsed) {
            if (!parsed) {
                return;
            }
            if ((parsed === null || parsed === void 0 ? void 0 : parsed.accessToken) && (parsed === null || parsed === void 0 ? void 0 : parsed.refreshToken)) {
                _this.dispatch('onTokensUpdate', {
                    accessToken: parsed.accessToken,
                    refreshToken: parsed.refreshToken,
                });
                _this.dispatch('closeLoginDialog');
            }
            else if ((parsed === null || parsed === void 0 ? void 0 : parsed.event) === 'request-password-recovery') {
                _this.commit('redirect', { to: { name: 'request-password-recovery' } });
                _this.dispatch('closeLoginDialog');
            }
        })
            .catch(function (error) {
            console.dir(error);
            console.info('failed to parse', event.data);
        });
    };
    AuthActions.prototype.onTokensUpdate = function (tokens) {
        setTokens(tokens);
        this.dispatch('afterLogin');
    };
    AuthActions.prototype.initSilentRefresh = function () {
        var _this = this;
        this.state.silentRefresh = new SilentRefresh({
            onLogoutCallback: function () {
                _this.dispatch('logout');
            },
            loginByRefreshToken: loginByRefreshToken,
        });
        this.dispatch(getUserIdFromToken() ? 'afterLogin' : 'afterLogout');
    };
    AuthActions.prototype.logout = function () {
        forceLogout();
        this.dispatch('afterLogout');
    };
    AuthActions.prototype.afterLogin = function () {
        this.commit('setIsLoggedIn', true);
        if (this.state.silentRefresh) {
            this.state.silentRefresh.afterLogin();
        }
    };
    AuthActions.prototype.afterLogout = function () {
        this.commit('setIsLoggedIn', false);
        if (this.state.silentRefresh) {
            this.state.silentRefresh.afterLogout();
        }
    };
    AuthActions.prototype.afterEmailChange = function () {
        var _this = this;
        if (!this.state.isLoggedIn) {
            return;
        }
        var sub = getUserIdFromToken();
        if (!sub) {
            return;
        }
        loginByRefreshToken({
            accessToken: { sub: sub },
            refreshToken: window.localStorage.getItem('refreshToken'),
        }).then(function (response) {
            _this.dispatch('onTokensUpdate', response);
        });
    };
    return AuthActions;
}(Actions));
var auth = new Module({
    namespaced: true,
    state: AuthState,
    getters: AuthGetters,
    mutations: AuthMutations,
    actions: AuthActions,
});
export default auth;
export var authMapper = createMapper(auth);
