import Vue from 'vue';
import vIconFluid from 'shared/components/IconFluid';
export default Vue.extend({
    components: {
        vIconFluid: vIconFluid,
    },
    props: {
        icon: {
            type: String,
            default: 'error-search',
        },
        actionText: {
            type: String,
            default: '',
        },
        hideAction: {
            type: Boolean,
            default: false,
        },
        hideGaps: {
            type: Boolean,
            default: false,
        },
        isFetching: {
            type: Boolean,
            default: false,
        },
        isInvert: {
            type: Boolean,
            default: false,
        },
        isHorizontal: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: '',
        },
        to: {
            type: Object,
            default: function () { return null; },
        },
    },
    computed: {
        classes: function () {
            var _a;
            return _a = {
                    'page-empty--ungaps': this.hideGaps,
                    'page-empty--invert': this.isInvert,
                    'page-empty--horizontal': this.isHorizontal
                },
                _a["page-empty--" + this.theme] = this.theme,
                _a;
        },
    },
    methods: {
        navigateToHome: function () {
            this.$router.push({ name: 'dashboard' });
        },
    },
});
